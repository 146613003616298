@media only screen and (min-width: 960px) {
  .gridItem:nth-child(4n + 1) {
    grid-column: 1/14;
  }

  .gridItem:nth-child(4n + 2) {
    grid-column: 14 / -1;
  }

  .gridItem:nth-child(4n + 3) {
    grid-column: 1 / 12;
  }

  .gridItem:nth-child(4n + 4) {
    grid-column: 12 / -1;
  }
}

.gridItem:nth-child(4n + 2) {
  & > div {
    background: linear-gradient(135deg, #d9f6e9 0%, #d1e6f2 100%);
  }
}

.gridItem:nth-child(4n + 3) {
  & > div {
    background: linear-gradient(135deg, #c5e2d9 0%, #fafcfc 100%);
  }
}

.gridItem:nth-child(4n + 1) {
  & > div {
    background: linear-gradient(134deg, #dfeef7 0%, #c4d7de 100%);
  }
}

.gridItem:nth-child(4n + 4) {
  & > div {
    background: linear-gradient(135deg, #daf8f9 0%, #e5f0f0 100%);
  }
}
