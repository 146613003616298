.line {
    height: 0px;
    width: 1px;
    border-bottom: 1px solid #DFDFDF;
    -webkit-animation: increase .5s;
    -moz-animation: increase .5s;
    -o-animation: increase .5s;
    animation: increase .5s;
    animation-fill-mode: forwards;
}

@keyframes increase {
    100% {
        width: 100%
    }
}